@import "./bower/bootstrap/less/bootstrap";
@import "./bower/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";
/*
* Vendor specific
*/
@import "vendors/parsley";
@import "vendors/slick";

html, body {
  height: 100%;
}
.homepage{
  h1{
    border: 1px solid transparent;
    text-align: center;
  }
  padding-bottom: 30px;
}
#container {
  min-height: 100%;
  margin-bottom: -44px;
  padding-bottom: 44px;
}

#sidebar {
  padding-top: 1 * @font-size-h1;
}

.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.align-right {
  float: right;
  width: 33.3%;
  padding-left:20px;
  margin-bottom: 20px;
}

.align-left {
  float: left;
  width:33.3%;
  padding-right:20px;
  margin-bottom: 20px;

  .article-image{
    margin-bottom: 15px;
    margin-right: 15px;
  }

}

.align-right, .align-left {
  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  position: relative;
  top: 50%;
  .translate(0%, -50%);
}

.horizontal-align-center {
  .translate(-50%, -50%);
  left: 50%;
  position: absolute;
  z-index: 99;
}

.caption {
  font-size: @font-size-small;
  min-height: @font-size-small;
  box-sizing: content-box;

  p {
    margin-bottom: 0;
  }

  .article-image-attribution {
    float: right;
  }
}


.jumbotron {
  *:last-child {
    margin-bottom: 0;
  }
}

.page-title {
  text-align: center;
  margin-top: @font-size-h1;
  margin-bottom: @font-size-h1;

  &.page-title-left {
    text-align: left;
  }
}

.page-header {
  margin-top: @font-size-h1;
  h1, h2, h3 {
    margin-top: 0;
  }
}

.article {
  padding-bottom: @grid-gutter-width;

  .article-title {
    &:extend(.page-title);
  }

  .article-byline {
    position: relative;
    text-align: center;
    margin-bottom: @font-size-h1;
  }

  h2 {
    margin-bottom: 0.5em;
  }
}

.meta-byline {
  margin-bottom: @padding-base-vertical;
}

.article-image {
  margin-bottom: @line-height-computed;

  .caption {
    .clearfix();
    padding: 9px;
  }
}

[class*="col-"].align-left {
  padding-left: 0;
  padding-right: 15px;

  @media (max-width: @screen-md) {
    padding-right: 0;
  }
}

[class*="col-"].align-right {
  padding-right: 0;
  padding-left: 15px;
  @media (max-width: @screen-md) {
    padding-left: 0;
  }
}

[class*="col-"].align-center {
  text-align: center;
  .caption {
    text-align: left;
  }
  .thumbnail {
    display: inline-block;
  }
}

.article-image.align-full {

}

.article-image.align-left, .article-image.align-right {

  @media (min-width: @screen-xs) {
    max-width: 50%;
  }

  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
  }

}

.article-body {
  .clearfix();
}


// Media extends
.media {
  border: 1px solid @gray-light;
  padding: @panel-body-padding;
  margin-bottom: @font-size-base;
  border-radius: @border-radius-small;

  .media-right,
  .media > .pull-right {
    padding-left: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-left,
  .media > .pull-left {
    padding-right: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-date-byline {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: @padding-base-vertical;
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }

  &.media-responsive {
    .media-right, .media-left {

      @media (max-width: @screen-xs) {
        display: block;
        float: none !important;
        padding-right: 0;
        padding-left: 0;

        .media-object {
          width: 100%;
        }
      }

      @media (min-width: @screen-xs + 1) {
        .media-object {
          width: 200px;
        }
      }

      @media (min-width: @screen-md) {
        .media-object {
          width: 160px;
        }
      }

    }
  }

  &-galleries {
    padding: 4px;
    background-color: #fff;
    .media-summary {
      padding: @padding-small-vertical;
    }
  }
}

.nav-stacked {
  .nav-stacked {
    > li > a {
      padding-left: 40px;
    }
  }
}

.edit-this-page {
  font-size: 14px !important;
  background: #fff;
  display: inline-block;
  padding: 4px;
  height: 22px;
  width: 22px;
  line-height: 14px;
  color: @gray;

  &:hover {
    background: @gray;
    color: #fff;
  }
}

#footer {
  background: @gray;
}

.site-footer {
  background: @gray;
  color: #fff;
  text-align: center;
  padding: 10px;
}

// Nav bar dropdown

.navbar-nav > li.dropdown {
  > a {
    padding-right: 30px;
    &:after {
      .fa-icon();
      content: @fa-var-chevron-down;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 9px;
      font-size: 0.75em;
    }
  }
}

// Nav form

.navbar-form {
  .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid @gray-light;
    border-radius: 0;
    color: @gray-lighter;
    box-shadow: none;
  }
}

// share

.article-sharing {
  min-height: 22px;
  margin-left: -5px;
}

.share-btn {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  color: @text-color;
  font-size: 14px;
}

// List group extends
.list-group-item-meta {
  font-size: @font-size-small;
}

/* notifications */
#notifications {
  z-index: 101;
}

body.ltr #notifications {
  left: 0;
}

body.rtl #notifications {
  right: 0;
}

#notifications.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
}

#notifications {
  text-align: center;

  .notification {
    .alert;
  }
  .notification[class*="message"] {
  }
  .notification[class*="notice"] {
    .alert-info;
  }
  .notification[class*="error"] {
    .alert-danger;
  }

  .notification {
    margin-bottom: 0;
    border-radius: 0;

    a {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .notification + .notification {
    border-top: 0;
  }
}

// Dropdown sub-menu

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  .fa-icon();
  content: @fa-var-chevron-right;
  position: relative;
  top: ~"calc(50% - 6px)";
  right: -9px;
  font-size: 0.75em;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

h1.gtmwarning {
  text-align: center;
  background: red;
}

/* Gallery */

.grid-sizer, .grid-item {
  width: 33%;
  margin-bottom: 5px;

}

.gallery-image {
  padding: 5px;
  width: 33%;
  float: left;
  position: relative;
}

.gallery-image-link {
  display: block;
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;

  .img-responsive {
    position: absolute;
    .square(100%);
  }
  .caption {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    padding: 5px;
    color: #fff;
  }
}

.grid-item-width2 {
  width: percentage(8/12);
}

.grid-item-height2 {
  img {
    width: 100%;
    overflow: hidden;
  }
  .lightbox {
    padding-bottom: 100%;
  }
}

.gallery-link {
  position: relative;

  &:hover .caption {
    display: block;
  }

  .caption {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

}

.mfp-description {
  color: #ccc;
  margin-top: 10px;
}

a.thumbnail:hover, a.thumbnail:active {
  text-decoration: none;
}


// Form extends

.field.form-group {
  position: relative;
}

.sprout-email-button {
  padding: 0 !important;
  right: 6px !important;
  bottom: 11px !important;
  margin: 0 !important;
  line-height: 1 !important;
}

.field .instructions {
  color: lighten(@text-color, 25%);
  font-size: @font-size-small;
  margin-bottom: 5px;
  *:last-child {
    margin-bottom: 0;
  }
}

.control-label, label {
  + .instructions {
      margin-top: -5px;
  }
}

label.required:after {
  content: ' *';
  color: @brand-danger;
}

/* Customisations */

h1, h2, h3, h4, h5, h6 {
	font-family: @menu-font;
}

h1, h2 {
	font-weight:600;
}

h1 {
	color:@brand-primary;
	border-bottom: 1px solid #000;
    padding-bottom: 10px;
	margin-bottom:40px;
}

body {
	font-family:@body-font;
}

ul {
	padding-left:20px;
}

.site-header {
	background:@brand-primary;
	margin-bottom:0px;
	font-family:@menu-font;
}

.navbar-brand, .navbar-brand:hover, .navbar-brand:focus {
    background-color: #fff!important;
    height: 145px;
    border-bottom-right-radius: 100%;
    position: absolute;
    z-index: 1;
    padding-right: 65px;
	> img {
		width: 220px;
	}
}

.navbar-default .navbar-nav>li {
	> .dropdown-menu {
		border:0px;
		border-radius:0px;
		box-shadow:none;
		padding:0px;
		@media (min-width:@screen-lg-min) {
			background-color:@gray-lighter;
		}
		li>a:hover, li>a:focus {
			background-color:darken(@gray-lighter, 10%);
			color:@text-color;
		}
	}
	&.dropdown>a:after {
		top: 50%;
		right: -20px;
	}
	>a {
		color:@white;
		border-bottom: 1px solid transparent;
		padding: 13px 0px 2px 0px;
		margin: 0px 20px;
		&:focus, &:hover {
			font-weight:inherit;
			border-bottom: 1px solid @white;
		}
	}
	&.active {
		>a {
			font-weight:inherit;
			border-bottom: 1px solid @white;
		}
	}
}

.navbar-default .navbar-toggle {
	color:@white;
	background-color:@brand-primary;
	z-index:2;
	.icon-bar {
		background-color:@white;
	}
	&:hover, &:focus {
		background-color:@white;
		color:@brand-primary;
		.icon-bar {
			background-color:@brand-primary;
		}
	}
}
@media (max-width:@grid-float-breakpoint) {
	.navbar-collapse {
		padding-top: 100px;
	}
}


.panel {
	border:none;
	margin:0px;
	border-radius:0px;
	box-shadow:none;
	background-color:transparent;
	.page-header {
		border:none;
		text-align:center;
	}
}

.background {
	background-size:cover;
	background-position:center;
	min-height:200px;
}

.slide {
	min-height:480px;
	.caption {
		width:50%;
		padding-left:10px;
		min-width:300px;
		color:@white;
		font-family:@menu-font;
		height:100%;
		position:absolute;
		background: -moz-linear-gradient(left, rgba(0,150,160,1) 0%, rgba(75,171,203,1) 60%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(0,150,160,1) 0%,rgba(75,171,203,1) 60%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(0,150,160,1) 0%,rgba(75,171,203,1) 60%,rgba(125,185,232,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0096a0', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
		h1 {
			top: 50%;
			position: relative;
			transform: translateY(-50%);
			max-width: 400px;
			margin-right: auto;
			margin-left: auto;
			font-family:"PF BeauSans W01 Bold" !important;
			color:@white;
			border:none;
			font-weight:500;
			font-size:30px;
			text-transform:uppercase;
			letter-spacing:1px;
			span.parttwo {
				font-family: "PF BeauSans W01 Light" !important;
				font-weight:300;
			}
		}
	}
}

.slick-slider {
	margin-bottom:0px;
}

.slick-dots li.slick-active button:before {
	color:@brand-secondary;
	opacity:1;
}

.slick-dots li button {
    background: 0 0;
    display: block;
    height: 8px;
    width: 8px;
    outline: 0;
    line-height: 0;
    font-size: 0;
    color: blue;
    padding: 0px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 100%;
	&:before {
		font-size:10px;
		width:10px;
		height:10px;
		line-height:10px;
		opacity:1;
		color:transparent;
		left:-1px;
	}
}

.turquoise {
	background-color:@brand-secondary;
	height:100%;
	p, h1, h2, h3, h4, h5, h6, a {
		color:@white;
	}
	a {
		font-weight: bold;
	}
}



.icon {
    text-align: center;
    padding-bottom: 10px;
	padding-top:50px;
	img {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		width:100px;
	}
}

div.before {
	background-color: transparent;
	height: 50px;
    width: 120px;
    position: relative;
    top: -20px;
    border-radius: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin-bottom: -50px;
}

a.secondarynav {
	position:relative;
	&:hover, &:focus, &:active {
		text-decoration:none;
		.icon, .navlink, .before {
			background:darken(@brand-secondary,10%);
		}
	}
}

.navlink {
	text-align:center;
	padding-bottom:50px;
}

.logo {
	img {
		height:60px;
		margin:50px 25px;
		@media (max-width: 640px) {
			margin:50px auto;
		}
	}
}

.large-only {
	@media (max-width: @screen-lg-min) {
		display: none;
	}
}

#footer, .site-footer {
	background:@brand-primary;
	text-align:left;
	a {
		color:@white;
	}
}

.site-footer {
	padding:20px 0px;
}
ul.footernav {
	list-style:none;
	padding-left:0px;
	li {
		text-transform:uppercase;
		font-weight:bold;
		float:left;
		padding-right:23px;
		&:last-of-type {
			padding-right:0px;
		}
		a {
			color:@white;
			text-decoration:none;
			padding-bottom:20px;
			&:focus, &:hover {
				text-decoration:none;
			}
		}
		ul.subnav {
			list-style:none;
			padding-left:0px;
			max-width:160px;
			li {
				float:none;
				font-size:@font-size-small;
				font-weight:normal !important;
				text-transform:none;
				&:first-of-type {
					margin-top:10px;
				}
			}
		}
	}
}

.copyright {
	background:darken(@brand-primary,5%);
	color:@brand-secondary;
	font-size:@font-size-small;
	padding:10px 0px;
	text-align:center;
	a {
		color:@brand-secondary !important;
	}
}

.fa {
	width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 100%;
    vertical-align: middle;
    margin: 0px 5px;
}
.fa-facebook {
	background-color: #0670c6;
	padding-top: 4px;
}
.fa-twitter {
	background-color: #00bdff;
	padding-top: 4px;
}
.fa-instagram {
	background-color: #ff2e80;
	padding-top: 4px;
}
.fa-phone {
	background-color:#00a79d;
	padding-top:4px;
}

.feature-background {
	background-size:cover;
	height:470px;
	background-position:center;
}

.page-header {
    background-color: @brand-primary;
    color: @white;
    margin: 0px;
    padding: 10px 0px;
	img {
		height:60px;
	}
	h1 {
		margin:0px;
		font-size:28px;
		color:@white;
		border:none;
		padding-bottom:0px;
		line-height:65px;
	}
}

.brand-primary {
	color:@brand-primary !important;
}

.brand-secondary {
	color:@brand-secondary !important;
}

.breadcrumbs {
	background:@gray-light;
	.breadcrumb {
		padding: 10px 0px;
		margin-bottom: 0px;
		list-style: none;
		background-color: transparent;
		border-radius: 0px;
		font-size:@font-size-small;
	}
	ul.breadcrumb {
		color: @white;
		font-weight: bold;
		li::before {
			color:@brand-primary;
		}
		li > a {
			font-weight:normal;
		}
	}
}

#main {
	background-color:@gray-lighter;
	padding-bottom:100px;
	position:relative;
}

ul.sectionnav {
	margin-top:40px;
	padding-left:0px;
	@media (max-width:@screen-md-min) {
		display:none;
	}
	.item-level-1 {
		display:none;
		&.active {
			display:block;
			> a {
				display:none;
			}
			.dropdown-menu {
				display:block;
				position:relative;
				background:@menugray;
				border:none;
				box-shadow:none;
				border-radius:0px;
				padding:0px;
				> li {
					border-bottom:1px solid @white;
					&:last-of-type {
						border-bottom:none;
					}
					a:hover, a:focus, a.active {
						background:@brand-primary;
						color:@white;
					}
				}
			}
		}
	}
}

.matrix-smallimage {
    padding: 20px 0px;
}

.matrix-text {
	ul {
		padding:20px;
	}
}

.matrix-table {
	margin-bottom:20px;
	table {
		border:1px solid @text-color;
		width:100%;
		color:@brand-primary;
		@media screen and (max-width: @screen-sm) {
			border:0;
			thead {
				border: none;
				clip: rect(0 0 0 0);
				height: 1px;
				margin: -1px;
				overflow: hidden;
				padding: 0;
				position: absolute;
				width: 1px;
			}
		}
		tr {
			border:1px solid @text-color;
			@media screen and (max-width: @screen-sm) {
				border-bottom: 3px solid @text-color;
				display: block;
				margin-bottom: 20px;
			}
		}
		td, th {
			padding:5px 15px;
			&:nth-child(even) {
				background-color:#eceeee;
			}
			@media screen and (max-width: @screen-sm) {
				border-bottom: 1px solid @text-color;
				display: flex;
				text-align: left;
				&:before {
					/*
					* aria-label has no advantage, it won't be read inside a table
					content: attr(aria-label);
					*/
					content: attr(data-label);
					float: left;
					font-weight: bold;
					max-width:50%;
					min-width:50%;
					text-align:left;
					padding-right:15px;
				}
				&:last-child {
					border-bottom: 0;
				}
				&:nth-child(even){
					background-color:transparent;
				}
				&:first-child {
					background-color:#eceeee;
					font-weight:bold;
				}
			}
		}
	}
}

table.directory {
	width:100%;
	margin-top:40px;
	font-size:14px;
	@media screen and (max-width: @screen-sm) {
		border:0;
		thead {
			border: none;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}
	}
	tr {
		border-bottom:1px solid #e1e1e1;
		@media screen and (max-width: @screen-sm) {
			border-bottom: 3px solid @text-color;
			display: block;
			margin-bottom: 20px;
		}
	}
	td, th {
		padding:5px 15px;
		@media screen and (max-width: @screen-sm) {
			border-bottom: 1px solid @text-color;
			display: flex;
			text-align: left;
			&:before {
				/*
				* aria-label has no advantage, it won't be read inside a table
				content: attr(aria-label);
				*/
				content: attr(data-label);
				float: left;
				font-weight: bold;
				max-width:50%;
				min-width:50%;
				text-align:left;
				padding-right:15px;
			}
			&:last-child {
				border-bottom: 0;
			}
			&:first-child {
				background-color:#eceeee;
				font-weight:bold;
			}
		}
	}
}

.btn {
	border:none;
	border-radius:0px;
	p {
		margin:0px;
	}
}

.bannercontent {
    width: 70%;
    min-width: 300px;
    max-width: 100%;
    
    padding: 90px 40px;
	@media (min-height:@screen-md) {
		float: right;
	}
}

.row.banner {
	margin:0px;
	position:relative;
	.number {
		position: absolute;
		background: @brand-primary;
		border-radius: 100%;
		width: 60px;
		height: 60px;
		border: 5px solid #e1e1e1;
		text-align: center;
		vertical-align: middle;
		left: 50%;
		margin-left:-30px;
		font-size:35px;
		color:@white;
		line-height:50px;
		top:50%;
		margin-top:-30px;
	}
}

@media screen and (max-width:@screen-md) {
	.row.banner .number {
		top:170px;
		margin-top:0px;
	}
}

.numberpanelcontent {
	padding:40px;
	font-style:italic;
	color:@brand-primary;
	@media (min-width:@screen-md) {
		width:70%;
		min-width:300px;
		max-width:100%;

		&.left {
			float:right;
		}
		&.right {
			float:left;
		}
	}
}

.pagination {
    width: 100%;
    text-align: center;
	margin-top:70px;
	a, span.current {
		width: 30px;
		height: 30px;
		background-color: @brand-secondary;
		line-height: 30px;
		color: #fff;
		display: inline-block;
		text-align: center;
		border-radius: 100%;
		&:hover, &:focus {
			background-color:@brand-primary;
		}
		.fa {
			margin:0px;
		}
	}
	span.current {
		background-color:@brand-primary;
	}
}

.btn-white {
	background-color:@white;
	color:@brand-primary;
	font-weight:bold;
	&:hover, &:focus {
		background-color:@brand-primary;
		color:@white;
	}
}

.magazine {
	position:relative;
	overflow:hidden;
	@media (max-width: 500px) {
		width: 230px;
		margin: 20px auto;
	}
	.overlay {
		position: absolute;
		bottom: -100%;
		width: 100%;
		height: 100%;
		background: #061025;
		opacity: 0.9;
		-webkit-transition: bottom 0.8s ease;
		-moz-transition: bottom 0.8s ease;
		-o-transition: bottom 0.8s ease;
		-ms-transition: bottom 0.8s ease;
		transition: bottom 0.8s ease;
		.details {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
			color: @white;
			font-weight: bold;
			text-align: center;
		}
	}
	&:hover, &:focus {
		.overlay {
			bottom:0px;
		}
	}
}

.newsletter {
	border-bottom:1px solid #e1e1e1;
	padding:20px 0px;
}

input {
	border:1px solid @white;
	border-radius:5px;
	color:@white;
	background-color:transparent;
	padding: 5px 10px;
	margin:10px;
}

::placeholder {
	color:darken(@white,20%);
}
::-webkit-input-placeholder { /* Chrome */
  color: darken(@white,20%);
}
:-ms-input-placeholder { /* IE 10+ */
  color: darken(@white,20%);
}
::-moz-placeholder { /* Firefox 19+ */
  color: darken(@white,20%);
  opacity: 1;
}
:-moz-placeholder { /* Firefox 4 - 18 */
  color: darken(@white,20%);
  opacity: 1;
}

select {
	background-color:@brand-primary;
	width: 200px;
	padding: 5px 10px 8px 10px;
	border-radius: 5px;
	margin: 0px 10px;
	option:hover, option:focus {
		background-color:@gray-lighter;
		color:@text-color;
	}
	@media (max-width:@screen-md) {
		margin-bottom:10px;
	}
}

form.subscribe {
	label {
		display:none;
	}
	input#fieldEmail {
		background: transparent;
		border: 1px solid #fff;
		color: #fff;
		border-radius: 0px;
		padding: 1px 5px 4px 5px;
		margin: 0px;
	}
	button {
		background: transparent;
		border: 1px solid #fff;
		padding: 1px;
		border-left: none;
	}
}

.section {
	clear: both;
	padding: 0px;
	margin: 0px;
}

/*  COLUMN SETUP  */
.col {
	display: block;
	float:left;
	margin: 1% 0 1% 1.6%;
}
.col:first-child { margin-left: 0; }

/*  GROUPING  */
.group:before,
.group:after { content:""; display:table; }
.group:after { clear:both;}
.group { zoom:1; /* For IE 6/7 */ }

/*  GRID OF THREE  */
.span_3_of_3 { width: 100%; }
.span_2_of_3 { width: 66.13%; }
.span_1_of_3 { width: 32.26%; }

/*  GO FULL WIDTH BELOW 500 PIXELS */
@media only screen and (max-width: 500px) {
	.col {  margin: 1% 0 1% 0%; }
	.span_3_of_3, .span_2_of_3, .span_1_of_3 { width: 100%; }
}

.socialheader {
	position:absolute;
	top:30px;
	right:50px;
	z-index:1;
	.platform {
		margin-bottom:10px;
	}
	a {
		color:@white;
	}
	@media (max-width:@screen-sm) {
		display:none;
	}
}

ul.socialmenu {
	@media (min-width:@screen-sm) {
		display:none;
	}
	list-style: none;
    padding-left: 0px;
    padding-bottom: 30px;
    padding-top: 5px;
	li.platform {
		float: left;
		a {
			color:@white;
		}
	}
}
.navbar-nav .open .dropdown-menu>li>a {
	padding: 5px 15px 5px 20px;
}

@media (max-width: 1199px) {
	.navbar-nav .open .dropdown-menu {
		margin-top: 10px;
	}
}

.body-carousel {
  .carousel-item {
    border-color:1px solid #0f6cb6;
    // box-shadow: 0 0 6px 2px rgba(0,0,0,.31);
    background-color: #fff;
    position: relative;
    z-index: 1;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px;
  }
  .media-heading {
    font-size:21px;
    margin:10px 0;
  }
  .media-body {
    padding:10px;
  }
  .slick-next:before, .slick-prev:before {
      font-family: slick;
      font-size: 20px;
      line-height: 1;
      color: #2f3292;
      opacity: .75;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; 
  }
  .read-more {
    background-color: #2e3192;
    color:#fff;
    padding: 10px 20px;
    text-decoration: none;
    font-size:12px;
  }

  .read-more:hover {
    background-color: #0196a0;
  }
}